import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Dropdown } from 'react-bootstrap';
import axios from 'axios';

function Firmvarev2() {
    const [loading, setLoading] = useState(true)
    const [firwareList, setFirwareList] = useState([])
    const [firmwareText, setFirmwareText] = useState("")
    const [currentVersion, setVersion] = useState("Version")
    const [currentFirmwareVersion, setFirmawareVersion] = useState("Firmvare version")
    const [firmwareUrl, setFirmwareUrl] = useState("Firmvare url")


    useEffect(() => {
        console.log("firmware mount");
        updateFirwares()
    }, []);

    function updateFirwares() {
        axios.get('/api/firmwares/getall')
            .then(response => {console.log(response.data); setLoading(false); setFirwareList(response.data); });
    }

    function setRelease(isRelease, id) {
        console.log(id);
        setLoading(true)
        axios.post('/api/firmwares/setRelease', {isRelease: isRelease, id: id})
            .then(response => {updateFirwares()});
    }
    
    function del(id){
        console.log(id);
        setLoading(true)
        axios.post('/api/firmwares/delete', {id: id})
            .then(response => {updateFirwares()});
    }

    function renderForecastsTable(forecasts) {
        return (
          <table className='table table-striped' aria-labelledby="tabelLabel">
            <thead>
              <tr>
                <th>Version</th>
                <th>Devise version</th>
                <th>Time create</th>
                <th>Url</th>
                <th>Apply</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {forecasts.map(forecast =>
                <tr key={forecast.version}>
                  <td>{forecast.version}</td>
                  <td>{forecast.deviceVersion}</td>
                  <td>{forecast.timeCreate}</td>
                  <td>{forecast.url}</td>
                  <td><button className="btn btn-primary" onClick={() => {setRelease(!forecast.isRelease, forecast.id);}}>{forecast.isRelease ? "To debug" : "To release"}</button></td>
                  <td><button className="btn btn-danger" onClick={() => {del(forecast.id);}}>X</button></td>
                </tr>
              )}
            </tbody>
          </table>
        );
    }

    function onSubmitFile(event) {
        event.preventDefault();
        console.log(firmwareUrl);
        console.log(currentFirmwareVersion);
        console.log(currentVersion);

        axios.post('/api/firmwares/addFirmware', {firmwareUrl: firmwareUrl, firmwareVersion: currentFirmwareVersion, deviceVersion: currentVersion})
            .then(response => {updateFirwares()});
    }

    const onChangeFirmwareVersion = (event) => {
        event.preventDefault();
        setFirmawareVersion(event.target.value);
    };

    const onChangeFirmwareUrl = (event) => {
        event.preventDefault();
        setFirmwareUrl(event.target.value);
    };

    return(<>
    <div className='blocks'>
        <div className='body-box'>
            <h1>Firmware</h1>
            <Form onSubmit={onSubmitFile}>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Firmware version</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width: "100%"}}>
                            {currentVersion}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setVersion('2002')}>2002</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Firmware version" value={currentFirmwareVersion} onChange={onChangeFirmwareVersion} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Url" value={firmwareUrl} onChange={onChangeFirmwareUrl} />
                </Form.Group>
                
                <Button variant="primary" type="submit">
                        Load
                </Button>
            </Form>
            <h2>{firmwareText}</h2>

            {loading ? <p><em>Loading...</em></p> : renderForecastsTable(firwareList)}
            
        </div>
      </div>
    </>)
}

export default Firmvarev2;