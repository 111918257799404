import React, { useState, useEffect, useRef, version } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar, Check, Modal, Tabs, Tab} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';
import './Drones.css';
import ModemCard from './ModemCard';
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
import ModemMainP from "./ModemMainP"

function Modem({drone, isConfirmEmail, isNebosvodAuth, nebosvodAuthLogin, onNebosvodAuth}){
    const { t } = useTranslation(['modem']);
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;
    
    const [hubTest, setHubTest] = useState(hubConnect);
    const [attitude, setAttitude] = useState(null);
    const [rate, setRate] = useState(null);
    const [firmwareLoad, setFirmwareLoad] = useState(<></>);
    const [firmwareLoadState, setFirmwareLoadState] = useState(0);
    const [firmwareLoadProgress, setFirmwareLoadProgress] = useState(null);

    const [isFrVis, setIsFrVis] = useState(drone.isFrVis);
    const [showUnbindModel, setShowUnbindModel] = useState(false);
    const [ognId, setOgnId] = useState(drone.ognId);
    const [ognSaveInfo, setOgnSaveInfo] = useState("");
    
    //const [isNebosvodAuth, setIsNebosvodAuth] = useState(isNebosvodAuth)
    const [nebosvodLogin, setNebosvodLogin] = useState("");
    const [nebosvodPass, setNebosvodPass] = useState("");
    
    const [nebosvodSaveInfo, setNebosvodSaveInfo] = useState("")
    const [nebosvodModemSaveInfo, setNebosvodModemSaveInfo] = useState("")
    const [isNebosvodVis, setIsNobosvodVis] = useState(drone.nebosvodInfo?.isVis ?? false)
    const [nebosvodOprtnId, setNebosvodOprtnId] = useState(drone.nebosvodInfo?.oprtnId ?? "")
    const [nebosvodSerNum, setNebosvodSerNum] = useState(drone.nebosvodInfo?.serNum ?? "")
    const [nebosvodRegIndex, setNebosvodRegIndex] = useState(drone.nebosvodInfo?.regIndex ?? "")

    const [modemSettings, setModemSettings] = useState(null)
    const [rtspStr, setRtspStr] = useState(null)
    const [fpvModeSettings, setFpvModSettings] = useState(false);
    const [fpvLatencySettings, setFpvLatencySettings] = useState("0");
    const [saveSettingsInfo, setSaveSettingsInfo] = useState(<></>)



    const [isOnline, setIsOnline] = useState(false);
    const imei = useRef("");
    
    
    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();
        connection.on('Attitude', (message) => {
            if(imei.current === message.imei){
                setAttitude(message);
                //console.log("imei === imei")
            }
            else{
                //console.log("imei !== imei")
            }
        });
        connection.on('FirmwareProgress', (message) => {
            if(imei.current === message.imei){
                setFirmwareLoadState(message.progress);
                setFirmwareLoadProgress(<>
                   <ProgressBar now={message.progress} label={`${message.progress}%`} />
                </>);
                if(message.progress == 100){
   
                }
            }
            else{
                //console.log("imei !== imei")
            }
             
            });
        connection.on('BaudRate', (message) => {
            if(imei.current === message.imei){
                setRate(message.baudRate)
            }
            else{
                //console.log("imei !== imei")
            }
            
        });

        connection.on('SetOnline', (message) => {
            setIsOnline(message.isOnline);
            console.log("setOnline");
            console.log(message);
        });
        connection.start();
        return connection;
    }

    useEffect(() => {
        return () => {
            console.log("modem.js unmount");
            hubTest.stop();
        };
    }, []);

    useEffect(() => {
        console.log("drone remount");
        setOgnId(drone.ognId);
        setIsFrVis(drone.isFrVis)
        setOgnSaveInfo("");
        setRate(null);
        setFirmwareLoad(<></>);
        setIsOnline(drone.isOnline)
        imei.current = drone.imei;

        setIsNobosvodVis(drone.nebosvodInfo?.isVis ?? false)
        setNebosvodOprtnId(drone.nebosvodInfo?.oprtnId ?? "")
        setNebosvodSerNum(drone.nebosvodInfo?.serNum ?? "")
        setNebosvodRegIndex(drone.nebosvodInfo?.regIndex ?? "")

        setModemSettings(null)
        setSaveSettingsInfo(<></>)
        getModemSettings(drone.imei)
    }, [drone]);

    
    function loadLastFirmware(){
        axios.post('api/drone/uploadfirmware', {imei: drone.imei})
            .then(response => {console.log(response);
                if(response.data.state == "Ok"){
                    if(drone.version < 2002) {
                        setFirmwareLoad(<>
                            <p>Load firware: {firmwareLoadState}%</p>
                        </>);
                    } else {
                        setFirmwareLoad(<>
                            <p>{t('firmware_in_process_updating')}</p> 
                        </>);
                    }
                }
                if(response.data.state == "NotPressedBind"){
                    setFirmwareLoad(<>
                        <p>Not pressed bind</p>
                    </>);
                }

                if(response.data.state == "ModemNotOnline"){
                    setFirmwareLoad(<>
                        <p>Modem not online</p>
                    </>);
                }
            });
    }

    function setBaudRate(value){
        axios.post('api/drone/setBaudRate', {baudRate: value, imei: drone.imei})
            .then(response => {
                console.log(response);
                if(response.data.state == "Ok"){
                    
                }
                if(response.data.state == "ModemNotOnline"){
                    setFirmwareLoad(<>
                        <p>Modem not online</p>
                    </>);
                }
            });
    }

    function unbind(){
        setShowUnbindModel(true)
    }

    function confirmUnbind(){
        axios.post('api/drone/unbindModem', {imei: drone.imei})
        .then(response => {
            console.log(response);
            window.location.reload();
        });
    }

    function switchFrVis(){
        
        axios.post('api/fr/setOgnView', {isFrVis: !isFrVis, imei: drone.imei})
            .then(response => {
                console.log(response);
        });
        setIsFrVis(!isFrVis);
    }

    function saveOgnId(){
        axios.post('api/fr/setOgnId', {ognId: ognId, imei: drone.imei})
            .then(response => {
                setOgnSaveInfo(t('successfully'));
        })
        .catch(exception => {
            setOgnSaveInfo(t('ogn_error'));
        });
    }

    function loginNebosvod(){
        axios.post('api/nebosvod/getToken', {password: nebosvodPass, login: nebosvodLogin})
        .then(response => {
            setNebosvodSaveInfo(t('successfully'));
            onNebosvodAuth(nebosvodLogin)
        })
        .catch(exception => {
            setNebosvodSaveInfo(t('error'));
        });
    }

    function saveNebosvodModemInfo(){
        axios.post('api/nebosvod/saveNebosvodModemInfo', {serNum: nebosvodSerNum, regIndex: nebosvodRegIndex, oprtnId: nebosvodSerNum, imei: drone.imei, isVis: isNebosvodVis})
        .then(response => {
            setNebosvodModemSaveInfo(t('successfully'));
        })
        .catch(exception => {
            setNebosvodModemSaveInfo(t('login_or_pass_error'));
        });
    }

    function nebosvodLogOut(){
        axios.post('api/nebosvod/logOut')
        .then(response => {
            window.location.reload();
        })
        .catch(exception => {
            setNebosvodModemSaveInfo(t('logout_error'));
        });
    }

    function switchNebosvodVis(){
        setIsNobosvodVis(!isNebosvodVis)
    }

    function normalizeFirwareVersion(version){
        if(drone.version < 2002){
            var ver = version.toString()
            if(ver.length >= 3){
                var str1 = ver.substring(0,1)
                var str2 = ver.substring(1,2)
                var str3 = ver.substring(2,ver.length)
                return str1 + "." + str2 + "." + str3
            } else {
                return version;
            }
        }
        else 
        {
            // 00 00 00 00
            var patch = (version & 0x000000ff) >> 8 * 0;
            var minor = (version & 0x0000ff00) >> 8 * 1;
            var major = (version & 0x00ff0000) >> 8 * 2;

            return major + "." + minor + "." + patch;
        }
    }

    function saveModemSettings() {
        axios.post('api/modemSettings/saveSettings', {imei: drone.imei, rtspUrl: rtspStr, fpvMode: fpvModeSettings, fpvLatency: fpvLatencySettings.toString()})
            .then(response => {setSaveSettingsInfo(<label>Настройки сохранены</label>)})
            .catch((error) => {
                console.log(error.response);
                setSaveSettingsInfo(<label>Ошибка сохранения настроек</label>)
            });
    }

    function getModemSettings(imei) {
        axios.get('api/modemSettings/settings?imei=' + imei)
            .then(response => {console.log("modem settings:"); 
                console.log(response.data);
                setModemSettings(response.data);
                setRtspStr(response.data?.rtspUrl);
                setFpvModSettings(response.data?.fpvMode); 
                setFpvLatencySettings(response.data?.fpvLatency);
            });
    }

    return(
        <>
        <Tabs defaultActiveKey="modem" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="modem" title={t('main_page')}>
                <ModemMainP drone={drone} isConfirmEmail={isConfirmEmail}/>
            </Tab>
            <Tab eventKey="ogn" title="OGN/FlightRadar">
                <div className='ong_info'>
                    <div>Ogn Id: </div>
                    <Form.Control type="text" placeholder={ognId} onChange={(event) => {setOgnId(event.target.value)}} />
                    <Button onClick={saveOgnId}>{t('save')}</Button>
                    <div>{ognSaveInfo}</div>
                </div>
                <Form.Check 
                    type={'radio'}
                    id={`default-${'radio'}`}
                    label={t('send_data_to_ogn')}
                    checked = {isFrVis}
                    onClick = {() => {switchFrVis()}}
                    style = {{marginTop: "20px"}}
                />
            </Tab>
            {i18n.language === "ru" ? 
                <Tab eventKey="nebosvod" title="Небосвод">
                    <div className='nebosvod_info'>
                        {isNebosvodAuth ? 
                        <>
                            <div>Логин в системе <a href='https://skyarc.ru/' target="_blank">Небосвод:</a> {nebosvodAuthLogin}</div>
                            <Button onClick={nebosvodLogOut}>Выход</Button>
                            <div></div>

                            <div>Серийный номер по CTA-2063-A или в формате производителя: </div>
                            <Form.Control type="text" placeholder={nebosvodSerNum} onChange={(event) => {setNebosvodSerNum(event.target.value)}} />

                            <div></div>

                            <div>Регистрационный знак или учетный номер БВС: </div>
                            <Form.Control type="text" placeholder={nebosvodRegIndex} onChange={(event) => {setNebosvodRegIndex(event.target.value)}} />

                            <Form.Check 
                                type={'radio'}
                                id={`default-${'radio'}`}
                                label={`Отправлять данные в систему "Небосвод"`}
                                checked = {isNebosvodVis}
                                onClick = {() => {switchNebosvodVis()}}
                                style = {{marginTop: "20px"}}
                            />

                            <Button onClick={saveNebosvodModemInfo}>Сохранить</Button>
                            <div>{nebosvodModemSaveInfo}</div>
                            
                        </> : 
                        <>
                            <div>Введите данные системы <a href='https://skyarc.ru/' target="_blank">Небосвод:</a> </div>
                            <div></div>
                            <div>Логин: </div>
                            <Form.Control type="text" placeholder={nebosvodLogin} onChange={(event) => {setNebosvodLogin(event.target.value)}} />
                            <div>Пароль: </div>
                            <Form.Control type="password" placeholder={nebosvodPass} onChange={(event) => {setNebosvodPass(event.target.value)}} />
                            <Button onClick={loginNebosvod}>Войти</Button>
                            <div>{nebosvodSaveInfo}</div>
                        </>}
                        
                    </div>
                    
                </Tab>
                :
            <>
            
            </>
            }
            
                <Tab eventKey="settings" title={t('settings')}>
                    <div className='baud_rate'>
                        {rate != null && drone.version < 2000 ? <p style={{marginBottom: "0px"}}>Baud rate: </p> : <></> }
                        {rate != null && drone.version < 2000 ? 
                        <>
                            <DropdownButton id="dropdown-basic-button" title={rate}>
                                <Dropdown.Item onClick = {() => setBaudRate(0)}>9600</Dropdown.Item>
                                <Dropdown.Item onClick = {() => setBaudRate(1)}>57600</Dropdown.Item>
                                <Dropdown.Item onClick = {() => setBaudRate(2)}>115200</Dropdown.Item>
                            </DropdownButton>
                        </>
                        :   
                        <></> }
                    </div>

                    {modemSettings?.rtspUrl == null ? <></> :
                    <div className='modem_settings_row'>
                        <div className='modem_settings_col_4'>
                            <h4>Основные настройки</h4>
                        </div>
                        <div className='modem_settings_col_8'>
                            <div className='m-form-group'>
                                <label>RTSP</label>
                                <Form.Control className='input_settings' type="text" value={rtspStr} onChange={(event) => {setRtspStr(event.target.value)}} />
                                <small className='text-gl-muted'>Строка RTSP подключения к ip камере</small>
                            </div>
                            <div className='m-form-group'>
                                <label>FPV Режим</label>
                                <DropdownButton className='dropdown_settings' align={{t:"w"}} id="dropdown-settings" title={fpvModeSettings ? "Вкл" : "Выкл"}>
                                    <Dropdown.Item id='dropdown-item-settings' onClick = {() => setFpvModSettings(true)}>Вкл</Dropdown.Item>
                                    <Dropdown.Item id='dropdown-item-settings' onClick = {() => setFpvModSettings(false)}>Выкл</Dropdown.Item>
                                </DropdownButton>
                                {
                                    fpvModeSettings ? <>
                                        <Form.Control className='input_settings' type="number" value={fpvLatencySettings} onChange={(event) => {setFpvLatencySettings(event.target.value)}} />
                                        <small className='text-gl-muted'>Задержка мс</small>
                                    </>
                                    :
                                    <>
                                        <small className='text-gl-muted'>Уменьшает задержку, но может повлиять на качество изображения</small>
                                    </>
                                }
                                
                            </div>
                        </div>
                        <div className='modem_settings_col_4'>
                            <Button style={{marginTop: "20px"}} onClick={saveModemSettings}>Сохранить</Button>
                        </div>
                        <div className='modem_settings_col_6'>
                            {saveSettingsInfo}
                        </div>
                        <div className='modem_settings_col_12'>
                            <hr/>
                        </div>
                    </div>}
                    {drone != null ? 
                        <Button variant="primary" style={{marginTop: "20px"}} onClick={unbind}>Unbind</Button>
                        :
                        <></>
                    }

                    <Modal  show={showUnbindModel} onHide={() => {setShowUnbindModel(false)}}>
                        <Modal.Header closeButton>
                        <Modal.Title style={{color: "black"}}>{t('caution')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{color: "black"}}>{t('are_you_sure_unbind')}</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => {setShowUnbindModel(false)}}>
                            {t('close')}
                        </Button>
                        <Button variant="primary" onClick={confirmUnbind}>
                            {t('unbind')}
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </Tab>
                <Tab eventKey="firmware" title={t('firmware')}>
                    <p>{t('firmware_version')}: {normalizeFirwareVersion(drone.firmwareVersion)}</p>
                    {firmwareLoad}
                        <p></p>
                        {firmwareLoadProgress}
                        { drone.upgradable ? 
                            <>
                                <p>Для обновления ПО выполните следующие 4 шага:</p>
                                <div className='upgrade_steps'>
                                    <p>1. Скачайте <a href={drone.upgradeUrl}>архив</a> с образом</p>
                                    <p>2. Скачайте <a href="https://win32diskimager.org/">Win32 Disk Imager</a></p>
                                    <p>3. Запишите образ sdcard.img (файл находится в архиве) на SD карту с помощью Win32 Disk Imager</p>
                                    <p>4. Вставьте SD карту в Air-link и дождитесь операции прошивки (индикатор STATUS мерцает белым цветом), после завершения прошивки загорится зеленый или красный индикатор. Если индикатор красный, попробуйте повторить процесс</p>
                                    <p>Подробная инструкция</p>
                                </div>
                            </> 
                            : 
                            <>
                                {drone.isLastFirmware == 0 ? 
                                    <p>{t('latest_firmware_version_is_installed')}</p> 
                                    : 
                                    <> 
                                        {firmwareLoadProgress != null ?
                                            <></>
                                            :
                                            <>
                                                {drone.isLastFirmware == 1 ? <>
                                                    <p>{t('firmware_version_is_outdated')}</p> 
                                                    <Button variant="primary" onClick={() => {loadLastFirmware()}}>
                                                        {t('load_latest_version')}
                                                    </Button>
                                                </>
                                                :
                                                <>
                                                    <p>{t('firmware_in_process_updating')}</p> 
                                                </>
                                                }
                                            </>   
                                        }
                                        
                                    </>
                                }
                            </>
                        }   
                </Tab>
            </Tabs>
        </>
    )
}

export default Modem;