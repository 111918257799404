import React, { useState, useEffect } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';
import './Drones.css';
import ModemCard from './ModemCard';
import Modem from './Modem';
import BindCard from './BindCard';
import Bind from './Bind';
import SortCard from './SortCard';

function Drones(){
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;
    
    const [hubTest, setHubTest] = useState(hubConnect);

    const [drone, setDrone] = useState(null);
    const [drones, setDrones] = useState([]);
    const [isOnline, setIsOnline] = useState(false);
    const [isConfirmEmail, setIsConfirmEmail] = useState(false)
    const [isNebosvodAuth, setIsNebosvodAuth] = useState(false)
    const [nebosvodAuthLogin, setNebosvodLogin] = useState("")

    const [isLoad, setIsLoad] = useState(false);
    const [isBind, setIsBind] = useState(false);
    const [modemCardOnlineTop, setModemCardOnlineTop] = useState(false);

    const [updateOnlineDroneFromHub, setUpdateDroneFromHub] = useState(null);

    useEffect(() => {
        console.log("drone mount");
        getDronesInfo()
    }, []);

    useEffect(() => {
        console.log("update drone hub");
        if(updateOnlineDroneFromHub != null){
            var ddd = drones.map(d => 
                {
                    var temp = Object.assign({}, d);
                    if(updateOnlineDroneFromHub.imei === temp.imei){
                        temp.isOnline = updateOnlineDroneFromHub.isOnline
                    }
                    return temp;
                }
            );
            //console.log(ddd);
            setDrones(ddd);
        } 
    }, [updateOnlineDroneFromHub]);

    useEffect(() => {
        return () => {
            console.log("drone.js unmount");
            hubTest.stop();
        };
    }, []);

    function getDroneInfo(){
        axios.get('api/drone/getModem')
        .then(response => {console.log("drone:"); 
            console.log(response.data); 
            setDrone(response.data); 
            setIsOnline(response.data.isOnline); 
            setIsLoad(true) }
        );
    }

    function getDronesInfo(){
        axios.get('api/drone/getModems')
        .then(response => {console.log("drone:"); 
            console.log(response.data);
            //todo: remove
            /*for (let i = 0; i < response.data.modemsInfo.length; i++) { // выведет 0, затем 1, затем 2
                response.data.modemsInfo[i].isOnline = (i % 2) == 1 
              }*/
            setDrones(response.data.modemsInfo);
            setIsConfirmEmail(response.data.isConfirmEmail)
            setIsNebosvodAuth(response.data.isNebosvodAuth)
            setNebosvodLogin(response.data.nebosvodLogin)
            setIsLoad(true);      
        }
        );
    }

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();
        connection.on('SetOnline', (message) => 
        { 
            setUpdateDroneFromHub(message);
        });
        connection.start();
        return connection;
    }

    function nebosvodLogin(login){
        setIsNebosvodAuth(true)
        setNebosvodLogin(login)
        console.log("nebosvod login: " + login)
    }

    function onlineTopClick() {
        setModemCardOnlineTop(!modemCardOnlineTop)
    }

    return(
        <div className='blocks'>
            <div className='drones'>
                <div className='modem-cards'>
                    {drones.length > 4 ? <SortCard onlineTopClick={() => { onlineTopClick() }} /> : <></>}
                    {modemCardOnlineTop ?
                        drones.sort((x, y) => x.name.localeCompare(y.name, "pl")).sort(x => x.isOnline ? -1 : 1).map(d => 
                            <ModemCard imei={d.imei} modemName={d.name} isOnline={d.isOnline} isActive={!(drone == null || d.imei !== drone.imei)} version={d.version} update={d.isLastFirmware} upgrade={d.upgradable} onClick={() => {setIsBind(false); setDrone(d);}}/>
                        ) 
                        : 
                        drones.map(d => 
                            <ModemCard imei={d.imei} modemName={d.name} isOnline={d.isOnline} isActive={!(drone == null || d.imei !== drone.imei)} version={d.version} update={d.isLastFirmware} upgrade={d.upgradable} onClick={() => {setIsBind(false); setDrone(d);}}/>
                        )
                    }
                    <BindCard onClick={() => {setIsBind(true); setDrone(null);}}/>
                </div>
                <div className='body-box'>
                    {isLoad ? 
                    <>
                        {isBind | (drone == null || drone.imei == null) ? 
                        <Bind/>
                        : <Modem drone={drone} isConfirmEmail={isConfirmEmail} isNebosvodAuth={isNebosvodAuth} nebosvodAuthLogin={nebosvodAuthLogin} onNebosvodAuth={(login) => { nebosvodLogin(login) }}/>}
                        
                    </>
                    :
                    <>
                    </>
                    }
                
                </div>
            </div>
        </div>
    )
    }
    

export default Drones;
